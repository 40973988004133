/*quill*/
body .ql-container {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 1rem;
}

body .ql-container h1,
body .ql-container h2 {
  color: #703786;
}

body .ql-container img {
  max-width: 100%;
  height: auto;
}

body .ql-container.ql-disabled .ql-tooltip {
  display: none;
}
