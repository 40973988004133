.slick-slide {
  height: auto;
}
.slick-slide div {
  margin-left: 10px;
  height: 100%;
}
.slick-track {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}
.slick-slider {
  position: relative;
}
.slick-prev,
.slick-next {
  position: absolute;
  top: -26px;
  left: auto;
}
.slick-prev:before,
.slick-next:before {
  color: #703886;
}
.slick-prev {
  left: auto;
  right: 21px;
}
.slick-next {
  right: -4px;
}
